var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleComponent',{ref:"mc",attrs:{"name":"consultantFarmerRelations","module-data":_vm.moduleData,"hide-filters":_vm.hideFilters,"no-bread-crumb":_vm.noBreadCrumb},on:{"changed":function($event){_vm.item = $event},"beforeSaveItem":_vm.beforeSaveItem},scopedSlots:_vm._u([{key:"tableRowActions",fn:function(ref){
var rowItem = ref.rowItem;
return undefined}},{key:"tableFilters",fn:function(ref){
var filters = ref.filters;
return [_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.CONSULTANTS.D')}},[_c('v-select2',{attrs:{"endPoint":"accounts","placeholder":_vm.t('GLOBAL.CONSULTANTS.D'),"filters":{
                accountTypeId: { value: _vm.Constants.AccountTypes.Consultant }
              },"sort":"name"},model:{value:(filters.consultantId.value),callback:function ($$v) {_vm.$set(filters.consultantId, "value", $$v)},expression:"filters.consultantId.value"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.FARMER')}},[_c('v-select2',{attrs:{"endPoint":"accounts","placeholder":_vm.t('GLOBAL.FARMER'),"sort":"name","filters":{
                accountTypeId: { value: _vm.Constants.AccountTypes.Farmer }
              }},on:{"input":function($event){filters.landId.value = null}},model:{value:(filters.farmerId.value),callback:function ($$v) {_vm.$set(filters.farmerId, "value", $$v)},expression:"filters.farmerId.value"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.LAND')}},[_c('v-select2',{attrs:{"endPoint":"lands","placeholder":_vm.t('GLOBAL.LAND'),"sort":"name","filters":{ farmerId: { value: filters.farmerId.value } }},model:{value:(filters.landId.value),callback:function ($$v) {_vm.$set(filters.landId, "value", $$v)},expression:"filters.landId.value"}})],1)],1),_c('b-col',{attrs:{"cols":"3","sm":"6","md":"3"}},[_c('b-form-group',{staticClass:"mb-3",attrs:{"label":_vm.t('GLOBAL.STATUS')}},[_c('vue-select',{attrs:{"label":"text","options":[
                { text: _vm.t('GLOBAL.ONLINE'), value: 1 },
                { text: _vm.t('GLOBAL.OFFLINE'), value: 0 }
              ],"multiple":false,"placeholder":_vm.t('GLOBAL.PLEASESELECT')},model:{value:(filters.status.value),callback:function ($$v) {_vm.$set(filters.status, "value", $$v)},expression:"filters.status.value"}})],1)],1)],1)],1)]}},{key:"form",fn:function(ref){
              var item = ref.item;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.FARMER')}},[_c('v-select2',{attrs:{"text":item.farmer ? item.farmer.name : '',"endPoint":"accounts","placeholder":_vm.t('GLOBAL.FARMER'),"filters":{
              accountTypeId: { value: _vm.Constants.AccountTypes.Farmer }
            },"sort":"name"},on:{"input":function($event){item.landId = null}},model:{value:(item.farmerId),callback:function ($$v) {_vm.$set(item, "farmerId", $$v)},expression:"item.farmerId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.LAND')}},[_c('v-select2',{attrs:{"text":item.land ? item.land.name : '',"endPoint":"lands","placeholder":_vm.t('GLOBAL.LAND'),"sort":"name","filters":{ farmerId: { value: item.farmerId } }},model:{value:(item.landId),callback:function ($$v) {_vm.$set(item, "landId", $$v)},expression:"item.landId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.CONSULTANTS.D')}},[_c('v-select2',{attrs:{"text":item.consultant ? item.consultant.name : '',"endPoint":"accounts","placeholder":_vm.t('GLOBAL.CONSULTANTS.D'),"filters":{
              accountTypeId: { value: _vm.Constants.AccountTypes.Consultant }
            },"sort":"name"},model:{value:(item.consultantId),callback:function ($$v) {_vm.$set(item, "consultantId", $$v)},expression:"item.consultantId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.REQUESTDATE')}},[_c('span',[_vm._v(_vm._s(item.requestDate))])])],1),_c('b-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.CONFIRMDATE')}},[_c('span',[_vm._v(_vm._s(item.confirmDate))])])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }