<template>
  <ModuleComponent
    ref="mc"
    name="consultantFarmerRelations"
    @changed="item = $event"
    :module-data="moduleData"
    @beforeSaveItem="beforeSaveItem"
    :hide-filters="hideFilters"
    :no-bread-crumb="noBreadCrumb"
  >
    <template v-slot:tableRowActions="{ rowItem }"></template>
    <template v-slot:tableFilters="{ filters }">
      <b-container fluid>
        <b-row>
          <b-col cols="12" sm="3" md="3">
            <b-form-group :label="t('GLOBAL.CONSULTANTS.D')">
              <v-select2
                v-model="filters.consultantId.value"
                endPoint="accounts"
                :placeholder="t('GLOBAL.CONSULTANTS.D')"
                :filters="{
                  accountTypeId: { value: Constants.AccountTypes.Consultant }
                }"
                sort="name"
              ></v-select2>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="3" md="3">
            <b-form-group :label="t('GLOBAL.FARMER')">
              <v-select2
                v-model="filters.farmerId.value"
                endPoint="accounts"
                :placeholder="t('GLOBAL.FARMER')"
                sort="name"
                :filters="{
                  accountTypeId: { value: Constants.AccountTypes.Farmer }
                }"
                @input="filters.landId.value = null"
              ></v-select2>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="3" md="3">
            <b-form-group :label="t('GLOBAL.LAND')">
              <v-select2
                v-model="filters.landId.value"
                endPoint="lands"
                :placeholder="t('GLOBAL.LAND')"
                sort="name"
                :filters="{ farmerId: { value: filters.farmerId.value } }"
              ></v-select2>
            </b-form-group>
          </b-col>

          <b-col cols="3" sm="6" md="3">
            <b-form-group :label="t('GLOBAL.STATUS')" class="mb-3">
              <vue-select
                v-model="filters.status.value"
                label="text"
                :options="[
                  { text: t('GLOBAL.ONLINE'), value: 1 },
                  { text: t('GLOBAL.OFFLINE'), value: 0 }
                ]"
                :multiple="false"
                :placeholder="t('GLOBAL.PLEASESELECT')"
              >
              </vue-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <template v-slot:form="{ item }">
      <b-row>
        <b-col cols="12" sm="12" md="6">
          <b-form-group :label="t('GLOBAL.FARMER')">
            <v-select2
              v-model="item.farmerId"
              :text="item.farmer ? item.farmer.name : ''"
              endPoint="accounts"
              :placeholder="t('GLOBAL.FARMER')"
              :filters="{
                accountTypeId: { value: Constants.AccountTypes.Farmer }
              }"
              sort="name"
              @input="item.landId = null"
            ></v-select2>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6">
          <b-form-group :label="t('GLOBAL.LAND')">
            <v-select2
              v-model="item.landId"
              :text="item.land ? item.land.name : ''"
              endPoint="lands"
              :placeholder="t('GLOBAL.LAND')"
              sort="name"
              :filters="{ farmerId: { value: item.farmerId } }"
            ></v-select2>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="4" md="4">
          <b-form-group :label="t('GLOBAL.CONSULTANTS.D')">
            <v-select2
              v-model="item.consultantId"
              :text="item.consultant ? item.consultant.name : ''"
              endPoint="accounts"
              :placeholder="t('GLOBAL.CONSULTANTS.D')"
              :filters="{
                accountTypeId: { value: Constants.AccountTypes.Consultant }
              }"
              sort="name"
            ></v-select2>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="4" md="4">
          <b-form-group :label="t('GLOBAL.REQUESTDATE')">
            <span>{{ item.requestDate }}</span>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="4" md="4">
          <b-form-group :label="t('GLOBAL.CONFIRMDATE')">
            <span>{{ item.confirmDate }}</span>
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </ModuleComponent>
</template>

<script>
// http://core.datadns.net:5000/swagger/index.html#model-Harvest
export default {
  name: "consultantFarmerRelations",
  methods: {
    beforeSaveItem(item) {
      item.confirm = true;
      item.requestDate = item.confirmDate = new Date();
    }
  },
  data() {
    return {
      item: {},
      moduleData: {
        name: "consultantFarmerRelations",
        primaryKey: "id",
        table: {
          sortBy: "id",
          sortDesc: true,
          graphqlQuery: `
				            id,
				            consultant{
				              name
				            },
				            farmer{
				              name
				            },
				            land{
				              name
				            }
						`,
          headers: [
            { text: "ID", value: "id", width: "50" },
            { text: this.t("GLOBAL.CONSULTANTS.D"), value: "consultant.name" },
            { text: this.t("GLOBAL.FARMERS.D"), value: "farmer.name" },
            { text: this.t("GLOBAL.LAND"), value: "land.name" }
          ],
          customColumns: [],
          filters: {
            consultantId: {
              type: "number",
              value: null
            },
            farmerId: {
              type: "number",
              value: null
            },
            landId: {
              type: "number",
              value: null
            },
            status: {
              type: "number",
              value: null
            }
          }
        },
        form: {
          maxWidth: "90%"
        }
      }
    };
  }
};
</script>
